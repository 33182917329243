<template>
  <div class="article__old-body">
    <div class="article__old-head">
      <div class="article__old-img">
        <img :src="article.img" alt="article" />
      </div>
    </div>
    <div class="article__old-content">
      <template v-if="article.content">
        <div class="article__old-info mb-3 mb-md-0">
          <p class="text tc-blue fs-18 fw-500">{{ cc.articleTypes[article.type] }}</p>
          <p class="text tc-smoke fs-18 fw-500">{{ filterDate(article.date) }}</p>
        </div>
        <h2 class="title fs-32 fw-600 lh-12 mb-3 mb-md-0">{{ getArticleContent(article.content).title }}</h2>
        <router-link tag="a" :to="`/${$i18n.locale}/blog/article/${article.key}`" class="link link--arrow link--td-none">
          <span class="link fs-16 fs-md-18 fs-xl-20 tc-blue">{{ cc.btn }}</span>
          <i class="ico ico--ui-arrow">
            <img src="/img/icons/ui/ico-any-arrow-right-blue.svg" alt="arrow" />
          </i>
        </router-link>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    article: {
      required: true,
      type: Object
    }
  },
  computed: {
    cc () {
      return this.$t('pages.blog')
    },
    locale () {
      return this.$i18n.locale
    }
  },
  methods: {
    filterDate (date) {
      const fd = new Date(date)
      const d = fd.getDate().length < 2 ? '0' + fd.getDate() : fd.getDate()
      const m = this.$store.state.months[this.locale][fd.getMonth()]
      const y = fd.getFullYear()
      return d + ' ' + m + ' ' + y
    },
    getArticleContent (data) {
      return this.locale in data ? data[this.locale] : data.en
    }
  }
}
</script>
