<template>
  <div class="page page--blog">
    <section class="section" id="sec-1" data-sec="1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <template v-if="!isFetching.last">
              <div class="article">
                <div class="article__new">
                  <div class="article__new-body">
                    <div class="article__new-content">
                      <p class="text tc-yellow fs-20 fw-500 mb-4">{{ cc.articleTypes[lastArticle.type] }}</p>
                      <h1 class="title fs-34 fs-md-42 fw-600 lh-12 mb-4">{{ getArticleContent(lastArticle.content).title }}</h1>
                      <p class="text tc-silver fs-16 fs-md-20 fw-400 mb-4 mb-mb-5 lh-15">{{ getArticleContent(lastArticle.content).text }}</p>
                      <router-link tag="a" :to="`/${$i18n.locale}/blog/article/${lastArticle.key}`" class="link link--arrow link--td-none">
                        <span class="link fs-16 fs-md-18 fs-xl-20 tc-yellow">{{ cc.btn }}</span>
                        <i class="ico ico--ui-arrow">
                          <img src="/img/icons/ui/ico-any-arrow-right-yellow.svg" alt="arrow" />
                        </i>
                      </router-link>
                    </div>
                    <div class="article__new-img">
                      <img :src="lastArticle.img" :alt="img">
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-2" data-sec="2">
      <div class="container">
        <div class="blog">
          <div class="row">
            <template v-if="!isFetching.older">
              <div v-for="(item, i) in olderArticles" :key="'article_' + i" :class="getColClassList(i)">
                <div class="article">
                  <div class="article__old" :class="'article__old--' + toLowerCase(item.type)">
                    <template v-if="item.type === 'MN'">
                      <article-mn :article="item"/>
                    </template>
                    <template v-else>
                      <article-default :article="item"/>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-3" data-sec="3"></section>
  </div>
</template>
<script>
import ArticleDefault from '@/components/article/main/ArticleDefault'
import ArticleMn from '@/components/article/main/ArticleMN'
export default {
  data: () => ({
    tmp: true,
    isFetching: {
      last: true,
      older: true
    },
    xApiKey: 'PinealApiPass.1456',
    lastArticle: [],
    olderArticles: [],
    colClassRow: {
      false: 'col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-5 offset-lg-1 col-xl-4 offset-xl-0',
      true: 'col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-5 offset-lg-0 col-xl-4'
    }
  }),
  components: {
    ArticleDefault, ArticleMn
  },
  methods: {
    getColClassList (i) {
      return this.colClassRow[i % 2 === 0]
    },
    async getLastArticle () {
      this.lastArticle = await fetch('/vendor/scripts/blog/actual.php', {
        method: 'POST'
      })
        .then((response) => { if (response.ok) return response.json() })
        .then((json) => { return json.data[0] })
        .catch((error) => { console.log(error) })
      this.isFetching.last = false
    },
    async getOlderArticles () {
      this.olderArticles = await fetch('/vendor/scripts/blog/older.php', {
        method: 'POST'
      })
        .then((response) => { if (response.ok) return response.json() })
        .then((json) => { return json.data })
        .catch((error) => { console.log(error) })
      this.isFetching.older = false
    },
    getArticleContent (data) {
      return this.locale in data ? data[this.locale] : data.en
    },
    toLowerCase (item) {
      return item.toLowerCase()
    }
  },
  computed: {
    cc () {
      return this.$t('pages.blog')
    },
    locale () {
      return this.$i18n.locale
    }
  },
  metaInfo () {
    const meta = this.$t('meta.blog')
    return {
      titleTemplate: '%s | ' + meta.name,
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    this.getLastArticle()
    this.getOlderArticles()
  }
}
</script>
